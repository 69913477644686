<template>
  <section>
    <v-container fluid>
      <v-row>
        <v-col md="12">
          <PageHeader
            :title="headercontent.title"
            :addlink="headercontent.addlink"
            :downloadlink="headercontent.downloadlink"
          />
        </v-col>
        <v-col cols="4">
          <v-select
            :items="titles"
            v-model="title"
            label="Title"
            outlined
          ></v-select>
        </v-col>

        <v-col cols="4">
          <v-select
            :items="connectedTos"
            v-model="connectedTo"
            label="Connected To"
            outlined
            item-text="title"
            item-value="_id"
          ></v-select>
        </v-col>
        <v-col cols="4" class="d-flex items-center">
          <v-btn
            style="width: 50%; height: 55px"
            class="primary"
            @click="filterAssets"
            >Filter</v-btn
          >
        </v-col>
        <v-col md="12" v-if="false">
          <h3 class="filtertitle">Filter {{ headercontent.title }}</h3>
          <v-form
            ref="form"
            v-model="valid"
            lazy-validation
            class="form-section"
          >
            <v-row>
              <v-col cols="3">
                <v-text-field
                  v-model="title"
                  outlined
                  label="Ads Titlte"
                  class="mr-btm-minus"
                ></v-text-field>
              </v-col>
              <v-col cols="3">
                <v-text-field
                  v-model="adsposition"
                  outlined
                  label="Ads Position"
                  class="mr-btm-minus"
                ></v-text-field>
              </v-col>
              <v-col cols="12" class="submitbtn">
                <v-btn
                  :disabled="!valid"
                  class="submitbutton btn-dark"
                  @click="validate"
                >
                  Filter {{ headercontent.title }}
                </v-btn>
                <!-- <v-progress-circular
         
                  indeterminate
                ></v-progress-circular> -->
              </v-col>
            </v-row>
          </v-form>
        </v-col>
        <v-col md="12">
          <v-simple-table class="table">
            <template v-slot:default>
              <thead>
                <tr>
                  <th class="text-left">S/N</th>
                  <th class="text-left">Name</th>
                  <th class="text-left">Image</th>
                  <th class="text-right">Action</th>
                </tr>
              </thead>
              <tbody>
                <tr colspan="4" v-if="allAssets.length == 0">
                  <td colspan="4" class="text-center">No data found</td>
                </tr>
                <tr v-for="(item, index) in allAssets" :key="index">
                  <td>{{ index + 1 }}</td>
                  <td>
                    {{ item.title }} <br />
                    {{ item.description }}
                  </td>

                  <td>
                    <img
                      :src="`${
                        item.title != 'Testimonials'
                          ? item.image
                          : item.profileImage
                      }`"
                      alt=""
                      class="imagebox"
                    />
                  </td>
                  <td class="text-right">
                    <v-icon
                      class="icons-action"
                      @click="deleteAssetAction(item._id)"
                    >
                      mdi-delete
                    </v-icon>
                  </td>
                </tr>
              </tbody>
            </template>
          </v-simple-table>
          <ul class="pagination-button" v-if="false">
            <li>Page : {{ (2 - 1) * 10 }} - 100</li>
            <li>
              <div>
                <v-btn @click="prevPage(0)" class="btn-dark">Prev</v-btn>

                <v-btn @click="nextPage(2)" class="btn-dark">Next</v-btn>
              </div>
            </li>
          </ul>
        </v-col>
      </v-row>
    </v-container>
  </section>
</template>

<script>
import PageHeader from "@/components/dashboard/PageHeader";
import { mapActions, mapGetters } from "vuex";
export default {
  name: "Asset",
  components: { PageHeader },
  data: () => ({
    headercontent: {
      title: "Assets Listing",
      addlink: "/dashboard/assets/add",
      downloadlink: false,
    },
    adsposition: "",
    valid: true,
    title: undefined,
    titles: [
      "HomeBanner",
      "AboutUsBanner",
      "SearchBanner",
      "Participants",
      "Recognized",
      "Certifications",
      "Testimonials",
      "PostAJob",
      "ContactUs",
      "TermsAndConditions",
      "PrivacyPolicy",
      "Blogs",
      "Training",
    ],
    connectedTos: [
      {
        title: "Page",
        _id: "page",
      },
    ],
    connectedTo: undefined,
  }),
  methods: {
    ...mapActions(["getAssets", "deleteAsset", "getTrainingCategories"]),
    validate() {},
    nextPage(data) {
      console.log(data);
    },
    prevPage(data) {
      console.log(data);
    },
    async deleteAssetAction(id) {
      if (!confirm("Are you sure you want to delete this item?")) {
        return;
      }
      await this.deleteAsset(id);
      await this.getAssets();
    },
    async filterAssets() {
      console.log("filtering assets");
      let data = {};
      if (this.title) {
        data.title = this.title;
      }
      if (this.connectedTo) {
        data.connectedTo = this.connectedTo;
      }
      await this.getAssets(data);
    },
  },
  computed: {
    ...mapGetters(["allAssets", "allTrainingCategories"]),
  },
  async created() {
    await this.getAssets();
    await this.getTrainingCategories();
    this.allTrainingCategories.results.map((item) => {
      this.connectedTos.push({
        title: item.title,
        _id: item._id,
      });
    });
  },
};
</script>

<style lang="scss" scoped>
@import "@/assets/scss/variables.scss";
.form-section {
  padding: 15px 0;
  .col {
    padding: 0 10px;
  }
}
.filtertitle {
  margin-bottom: 10px;
}
.btn-dark {
  background: $tagcolor !important;
  color: $white;
  margin-top: -10px;
}

.table {
  box-shadow: 0px -1px 5px 2px rgba(0, 0, 0, 0.1);

  thead {
    border-bottom: 2px solid $menucolor;
    background: $tagcolor;
  }
  th {
    font-size: $normal_lfont !important;
    color: $white !important;
  }
}
.pagination-button {
  display: flex;
  list-style: none;
  padding-left: 0;
  justify-content: space-between;
  box-shadow: 2px 2px 5px 2px rgba(0, 0, 0, 0.1);
  .btn-dark {
    margin-top: 0;
    margin-left: 10px;
  }
}
.table-heading,
.pagination-button {
  border: 1px solid $menucolor;
  margin-bottom: 0;
  padding: 10px;
  align-items: center;
}
.icons-action {
  margin: 10px;
  font-size: $iconfont;
}

.imagebox {
  width: 300px;
}
</style>
